import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProcessService } from '../../services/process.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Process } from '../../entities/process';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit, AfterViewInit {
  process: Process = new Process();
  year = '';
  report = '';
  constructor(public processService: ProcessService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.process = new Process();
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {

      this.year = params.get('year');
      this.report = params.get('reportType');
      return this.processService.getProcess(params.get('id'));
    }))
      .subscribe(process => {
        if (process !== null) {
          this.process = process;
        } else {
          this.process['key'] = this.report;
          this.process['year'] = this.year;
        }
      });
  }
  ngAfterViewInit() {

  }

}
