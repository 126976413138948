export class Countries {
    public countries = [
        {
            'country_code': 'AL',
            'country_name': 'Albania',
            'phone_code': 355,
            'flag_index': 432
        },
        {
            'country_code': 'DZ',
            'country_name': 'Algeria',
            'phone_code': 213,
            'flag_index': 1232
        },
        {
            'country_code': 'AF',
            'country_name': 'Afghanistan',
            'phone_code': 93,
            'flag_index': 384
        },
        {
            'country_code': 'AR',
            'country_name': 'Argentina',
            'phone_code': 54,
            'flag_index': 512
        },
        {
            'country_code': 'AE',
            'country_name': 'United Arab Emirates',
            'phone_code': 971,
            'flag_index': 368
        },
        {
            'country_code': 'AW',
            'country_name': 'Aruba',
            'phone_code': 297,
            'flag_index': 576
        },
        {
            'country_code': 'OM',
            'country_name': 'Oman',
            'phone_code': 968,
            'flag_index': 2832
        },
        {
            'country_code': 'AZ',
            'country_name': 'Azerbaijan',
            'phone_code': 994,
            'flag_index': 592
        },
        {
            'country_code': 'EG',
            'country_name': 'Egypt',
            'phone_code': 20,
            'flag_index': 1280
        },
        {
            'country_code': 'ET',
            'country_name': 'Ethiopia',
            'phone_code': 251,
            'flag_index': 1344
        },
        {
            'country_code': 'IE',
            'country_name': 'Ireland',
            'phone_code': 353,
            'flag_index': 1808
        },
        {
            'country_code': 'EE',
            'country_name': 'Estonia',
            'phone_code': 372,
            'flag_index': 1264
        },
        {
            'country_code': 'AD',
            'country_name': 'Andorra',
            'phone_code': 376,
            'flag_index': 352
        },
        {
            'country_code': 'AO',
            'country_name': 'Angola',
            'phone_code': 244,
            'flag_index': 480
        },
        {
            'country_code': 'AI',
            'country_name': 'Anguilla',
            'phone_code': 1264,
            'flag_index': 416
        },
        {
            'country_code': 'AG',
            'country_name': 'Antigua',
            'phone_code': 1268,
            'flag_index': 400
        },
        {
            'country_code': 'AT',
            'country_name': 'Austria',
            'phone_code': 43,
            'flag_index': 544
        },
        {
            'country_code': 'AU',
            'country_name': 'Australia',
            'phone_code': 61,
            'flag_index': 560
        },
        {
            'country_code': 'MO',
            'country_name': 'Macau',
            'phone_code': 853,
            'flag_index': 2496
        },
        {
            'country_code': 'BB',
            'country_name': 'Barbados',
            'phone_code': 1246,
            'flag_index': 624
        },
        {
            'country_code': 'PG',
            'country_name': 'Papua New Guinea',
            'phone_code': 675,
            'flag_index': 2896
        },
        {
            'country_code': 'BS',
            'country_name': 'The Bahamas',
            'phone_code': 1242,
            'flag_index': 816
        },
        {
            'country_code': 'PK',
            'country_name': 'Pakistan',
            'phone_code': 92,
            'flag_index': 2928
        },
        {
            'country_code': 'PY',
            'country_name': 'Paraguay',
            'phone_code': 595,
            'flag_index': 3024
        },
        {
            'country_code': 'PS',
            'country_name': 'Palestine',
            'phone_code': 970,
            'flag_index': 2976
        },
        {
            'country_code': 'BH',
            'country_name': 'Bahrain',
            'phone_code': 973,
            'flag_index': 704
        },
        {
            'country_code': 'PA',
            'country_name': 'Panama',
            'phone_code': 507,
            'flag_index': 2848
        },
        {
            'country_code': 'BR',
            'country_name': 'Brazil',
            'phone_code': 55,
            'flag_index': 800
        },
        {
            'country_code': 'BY',
            'country_name': 'Belarus',
            'phone_code': 375,
            'flag_index': 864
        },
        {
            'country_code': 'BM',
            'country_name': 'Bermuda',
            'phone_code': 1441,
            'flag_index': 752
        },
        {
            'country_code': 'BG',
            'country_name': 'Bulgaria',
            'phone_code': 359,
            'flag_index': 688
        },
        {
            'country_code': 'MP',
            'country_name': 'Northern Mariana Islands',
            'phone_code': 1670,
            'flag_index': 4016
        },
        {
            'country_code': 'BJ',
            'country_name': 'Benin',
            'phone_code': 229,
            'flag_index': 736
        },
        {
            'country_code': 'BE',
            'country_name': 'Belgium',
            'phone_code': 32,
            'flag_index': 656
        },
        {
            'country_code': 'IS',
            'country_name': 'Iceland',
            'phone_code': 354,
            'flag_index': 1904
        },
        {
            'country_code': 'PR',
            'country_name': 'Puerto Rico',
            'phone_code': 1,
            'flag_index': 2960
        },
        {
            'country_code': 'PL',
            'country_name': 'Poland',
            'phone_code': 48,
            'flag_index': 2944
        },
        {
            'country_code': 'BA',
            'country_name': 'Bosnia and Herzegovina',
            'phone_code': 387,
            'flag_index': 608
        },
        {
            'country_code': 'BO',
            'country_name': 'Bolivia',
            'phone_code': 591,
            'flag_index': 784
        },
        {
            'country_code': 'BZ',
            'country_name': 'Belize',
            'phone_code': 501,
            'flag_index': 880
        },
        {
            'country_code': 'PW',
            'country_name': 'Palau',
            'phone_code': 680,
            'flag_index': 3008
        },
        {
            'country_code': 'BW',
            'country_name': 'Botswana',
            'phone_code': 267,
            'flag_index': 848
        },
        {
            'country_code': 'BT',
            'country_name': 'Bhutan',
            'phone_code': 975,
            'flag_index': 832
        },
        {
            'country_code': 'BF',
            'country_name': 'Burkina Faso',
            'phone_code': 226,
            'flag_index': 672
        },
        {
            'country_code': 'BI',
            'country_name': 'Burundi',
            'phone_code': 257,
            'flag_index': 720
        },
        {
            'country_code': 'KP',
            'country_name': 'North Korea',
            'phone_code': 850,
            'flag_index': 2096
        },
        {
            'country_code': 'GQ',
            'country_name': 'Equatorial Guinea',
            'phone_code': 240,
            'flag_index': 1616
        },
        {
            'country_code': 'DK',
            'country_name': 'Denmark',
            'phone_code': 45,
            'flag_index': 1184
        },
        {
            'country_code': 'DE',
            'country_name': 'Germany',
            'phone_code': 49,
            'flag_index': 1152
        },
        {
            'country_code': 'TL',
            'country_name': 'Timor-Leste',
            'phone_code': 670,
            'flag_index': 3488
        },
        {
            'country_code': 'TG',
            'country_name': 'Togo',
            'phone_code': 228,
            'flag_index': 3440
        },
        {
            'country_code': 'DM',
            'country_name': 'Dominica',
            'phone_code': 1767,
            'flag_index': 1200
        },
        {
            'country_code': 'DO',
            'country_name': 'Dominican Republic',
            'phone_code': 1809,
            'flag_index': 1216
        },
        {
            'country_code': 'RU',
            'country_name': 'Russia',
            'phone_code': 7,
            'flag_index': 3104
        },
        {
            'country_code': 'EC',
            'country_name': 'Ecuador',
            'phone_code': 593,
            'flag_index': 1248
        },
        {
            'country_code': 'ER',
            'country_name': 'Eritrea',
            'phone_code': 291,
            'flag_index': 1312
        },
        {
            'country_code': 'FR',
            'country_name': 'France',
            'phone_code': 33,
            'flag_index': 1424
        },
        {
            'country_code': 'FO',
            'country_name': 'Faroe Islands',
            'phone_code': 298,
            'flag_index': 1408
        },
        {
            'country_code': 'PF',
            'country_name': 'French Polynesia',
            'phone_code': 689,
            'flag_index': 2880
        },
        {
            'country_code': 'GF',
            'country_name': 'French Guiana',
            'phone_code': 594,
            'flag_index': 3968
        },
        {
            'country_code': 'PM',
            'country_name': 'Saint Pierre and Miquelon',
            'phone_code': 508,
            'flag_index': 4064
        },
        {
            'country_code': 'VA',
            'country_name': 'Vatican City',
            'phone_code': 39,
            'flag_index': 3712
        },
        {
            'country_code': 'PH',
            'country_name': 'Philippines',
            'phone_code': 63,
            'flag_index': 2912
        },
        {
            'country_code': 'FJ',
            'country_name': 'Fiji',
            'phone_code': 679,
            'flag_index': 1376
        },
        {
            'country_code': 'FI',
            'country_name': 'Finland',
            'phone_code': 358,
            'flag_index': 1360
        },
        {
            'country_code': 'CV',
            'country_name': 'Cape Verde',
            'phone_code': 238,
            'flag_index': 1104
        },
        {
            'country_code': 'FK',
            'country_name': 'Falkland Islands',
            'phone_code': 500,
            'flag_index': 3952
        },
        {
            'country_code': 'GM',
            'country_name': 'The Gambia',
            'phone_code': 220,
            'flag_index': 1568
        },
        {
            'country_code': 'CG',
            'country_name': 'Republic of the Congo',
            'phone_code': 242,
            'flag_index': 912
        },
        {
            'country_code': 'CD',
            'country_name': 'Democratic Republic of the Congo',
            'phone_code': 243,
            'flag_index': 944
        },
        {
            'country_code': 'CO',
            'country_name': 'Colombia',
            'phone_code': 57,
            'flag_index': 1056
        },
        {
            'country_code': 'CR',
            'country_name': 'Costa Rica',
            'phone_code': 506,
            'flag_index': 1072
        },
        {
            'country_code': 'GD',
            'country_name': 'Grenada',
            'phone_code': 1473,
            'flag_index': 1472
        },
        {
            'country_code': 'GL',
            'country_name': 'Greenland',
            'phone_code': 299,
            'flag_index': 1552
        },
        {
            'country_code': 'GE',
            'country_name': 'Georgia',
            'phone_code': 995,
            'flag_index': 1488
        },
        {
            'country_code': 'GG',
            'country_name': 'Guernsey',
            'phone_code': 44,
            'flag_index': 1504
        },
        {
            'country_code': 'CU',
            'country_name': 'Cuba',
            'phone_code': 53,
            'flag_index': 1088
        },
        {
            'country_code': 'GP',
            'country_name': 'Guadeloupe',
            'phone_code': 590,
            'flag_index': 1600
        },
        {
            'country_code': 'GU',
            'country_name': 'Guam',
            'phone_code': 1671,
            'flag_index': 1664
        },
        {
            'country_code': 'GY',
            'country_name': 'Guyana',
            'phone_code': 592,
            'flag_index': 1696
        },
        {
            'country_code': 'KZ',
            'country_name': 'Kazakhstan',
            'phone_code': 7,
            'flag_index': 2160
        },
        {
            'country_code': 'HT',
            'country_name': 'Haiti',
            'phone_code': 509,
            'flag_index': 1760
        },
        {
            'country_code': 'KR',
            'country_name': 'South Korea',
            'phone_code': 82,
            'flag_index': 2112
        },
        {
            'country_code': 'NL',
            'country_name': 'Netherlands',
            'phone_code': 31,
            'flag_index': 2752
        },
        {
            'country_code': 'BQ',
            'country_name': 'Bonaire, Sint Eustatius and Saba',
            'phone_code': 599,
            'flag_index': 3920
        },
        {
            'country_code': 'ME',
            'country_name': 'Montenegro',
            'phone_code': 382,
            'flag_index': 2384
        },
        {
            'country_code': 'HN',
            'country_name': 'Honduras',
            'phone_code': 504,
            'flag_index': 1728
        },
        {
            'country_code': 'KI',
            'country_name': 'Kiribati',
            'phone_code': 686,
            'flag_index': 2048
        },
        {
            'country_code': 'DJ',
            'country_name': 'Djibouti',
            'phone_code': 253,
            'flag_index': 1168
        },
        {
            'country_code': 'KG',
            'country_name': 'Kyrgyzstan',
            'phone_code': 996,
            'flag_index': 2016
        },
        {
            'country_code': 'GN',
            'country_name': 'Guinea',
            'phone_code': 224,
            'flag_index': 1584
        },
        {
            'country_code': 'GW',
            'country_name': 'Guinea-Bissau',
            'phone_code': 245,
            'flag_index': 1680
        },
        {
            'country_code': 'CA',
            'country_name': 'Canada',
            'phone_code': 1,
            'flag_index': 896
        },
        {
            'country_code': 'GH',
            'country_name': 'Ghana',
            'phone_code': 233,
            'flag_index': 1520
        },
        {
            'country_code': 'GA',
            'country_name': 'Gabon',
            'phone_code': 241,
            'flag_index': 1440
        },
        {
            'country_code': 'KH',
            'country_name': 'Cambodia',
            'phone_code': 855,
            'flag_index': 2032
        },
        {
            'country_code': 'CZ',
            'country_name': 'Czech Republic',
            'phone_code': 420,
            'flag_index': 1136
        },
        {
            'country_code': 'ZW',
            'country_name': 'Zimbabwe',
            'phone_code': 263,
            'flag_index': 3888
        },
        {
            'country_code': 'CM',
            'country_name': 'Cameroon',
            'phone_code': 237,
            'flag_index': 1024
        },
        {
            'country_code': 'QA',
            'country_name': 'Qatar',
            'phone_code': 974,
            'flag_index': 3040
        },
        {
            'country_code': 'KY',
            'country_name': 'Cayman Islands',
            'phone_code': 1345,
            'flag_index': 2144
        },
        {
            'country_code': 'KM',
            'country_name': 'Comoros',
            'phone_code': 269,
            'flag_index': 2064
        },
        {
            'country_code': 'XK',
            'country_name': 'Kosovo',
            'phone_code': 381,
            'flag_index': 4160
        },
        {
            'country_code': 'CI',
            'country_name': 'Côte d\'Ivoire',
            'phone_code': 225,
            'flag_index': 976
        },
        {
            'country_code': 'KW',
            'country_name': 'Kuwait',
            'phone_code': 965,
            'flag_index': 2128
        },
        {
            'country_code': 'HR',
            'country_name': 'Croatia',
            'phone_code': 385,
            'flag_index': 1744
        },
        {
            'country_code': 'KE',
            'country_name': 'Kenya',
            'phone_code': 254,
            'flag_index': 2000
        },
        {
            'country_code': 'CK',
            'country_name': 'Cook Islands',
            'phone_code': 682,
            'flag_index': 992
        },
        {
            'country_code': 'CW',
            'country_name': 'Curaçao',
            'phone_code': 599,
            'flag_index': 3936
        },
        {
            'country_code': 'LV',
            'country_name': 'Latvia',
            'phone_code': 371,
            'flag_index': 2320
        },
        {
            'country_code': 'LS',
            'country_name': 'Lesotho',
            'phone_code': 266,
            'flag_index': 2272
        },
        {
            'country_code': 'LA',
            'country_name': 'Laos',
            'phone_code': 856,
            'flag_index': 2176
        },
        {
            'country_code': 'LB',
            'country_name': 'Lebanon',
            'phone_code': 961,
            'flag_index': 2192
        },
        {
            'country_code': 'LT',
            'country_name': 'Lithuania',
            'phone_code': 370,
            'flag_index': 2288
        },
        {
            'country_code': 'LR',
            'country_name': 'Liberia',
            'phone_code': 231,
            'flag_index': 2256
        },
        {
            'country_code': 'LY',
            'country_name': 'Libya',
            'phone_code': 218,
            'flag_index': 2336
        },
        {
            'country_code': 'LI',
            'country_name': 'Liechtenstein',
            'phone_code': 423,
            'flag_index': 2224
        },
        {
            'country_code': 'RE',
            'country_name': 'Réunion',
            'phone_code': 262,
            'flag_index': 3056
        },
        {
            'country_code': 'LU',
            'country_name': 'Luxembourg',
            'phone_code': 352,
            'flag_index': 2304
        },
        {
            'country_code': 'RW',
            'country_name': 'Rwanda',
            'phone_code': 250,
            'flag_index': 3120
        },
        {
            'country_code': 'RO',
            'country_name': 'Romania',
            'phone_code': 40,
            'flag_index': 3072
        },
        {
            'country_code': 'MG',
            'country_name': 'Madagascar',
            'phone_code': 261,
            'flag_index': 2400
        },
        {
            'country_code': 'IM',
            'country_name': 'Isle Of Man',
            'phone_code': 44,
            'flag_index': 1840
        },
        {
            'country_code': 'MV',
            'country_name': 'Maldives',
            'phone_code': 960,
            'flag_index': 2592
        },
        {
            'country_code': 'MT',
            'country_name': 'Malta',
            'phone_code': 356,
            'flag_index': 2560
        },
        {
            'country_code': 'MW',
            'country_name': 'Malawi',
            'phone_code': 265,
            'flag_index': 2608
        },
        {
            'country_code': 'MY',
            'country_name': 'Malaysia',
            'phone_code': 60,
            'flag_index': 2640
        },
        {
            'country_code': 'ML',
            'country_name': 'Mali',
            'phone_code': 223,
            'flag_index': 2448
        },
        {
            'country_code': 'MK',
            'country_name': 'Macedonia',
            'phone_code': 389,
            'flag_index': 2432
        },
        {
            'country_code': 'MH',
            'country_name': 'Marshall Islands',
            'phone_code': 692,
            'flag_index': 2416
        },
        {
            'country_code': 'MQ',
            'country_name': 'Martinique',
            'phone_code': 596,
            'flag_index': 2512
        },
        {
            'country_code': 'YT',
            'country_name': 'Mayotte',
            'phone_code': 262,
            'flag_index': 4176
        },
        {
            'country_code': 'MU',
            'country_name': 'Mauritius',
            'phone_code': 230,
            'flag_index': 2576
        },
        {
            'country_code': 'MR',
            'country_name': 'Mauritania',
            'phone_code': 222,
            'flag_index': 2528
        },
        {
            'country_code': 'US',
            'country_name': 'United States',
            'phone_code': 1,
            'flag_index': 3664
        },
        {
            'country_code': 'AS',
            'country_name': 'American Samoa',
            'phone_code': 1684,
            'flag_index': 528
        },
        {
            'country_code': 'VI',
            'country_name': 'US Virgin Islands',
            'phone_code': 1340,
            'flag_index': 3776
        },
        {
            'country_code': 'MN',
            'country_name': 'Mongolia',
            'phone_code': 976,
            'flag_index': 2480
        },
        {
            'country_code': 'MS',
            'country_name': 'Montserrat',
            'phone_code': 1664,
            'flag_index': 2544
        },
        {
            'country_code': 'BD',
            'country_name': 'Bangladesh',
            'phone_code': 880,
            'flag_index': 640
        },
        {
            'country_code': 'PE',
            'country_name': 'Peru',
            'phone_code': 51,
            'flag_index': 2864
        },
        {
            'country_code': 'FM',
            'country_name': 'Federated States of Micronesia',
            'phone_code': 691,
            'flag_index': 1392
        },
        {
            'country_code': 'MM',
            'country_name': 'Myanmar',
            'phone_code': 95,
            'flag_index': 2464
        },
        {
            'country_code': 'MD',
            'country_name': 'Moldova',
            'phone_code': 373,
            'flag_index': 2368
        },
        {
            'country_code': 'MA',
            'country_name': 'Morocco',
            'phone_code': 212,
            'flag_index': 2352
        },
        {
            'country_code': 'MC',
            'country_name': 'Monaco',
            'phone_code': 377,
            'flag_index': 1792
        },
        {
            'country_code': 'MZ',
            'country_name': 'Mozambique',
            'phone_code': 258,
            'flag_index': 2656
        },
        {
            'country_code': 'MX',
            'country_name': 'Mexico',
            'phone_code': 52,
            'flag_index': 2624
        },
        {
            'country_code': 'NA',
            'country_name': 'Namibia',
            'phone_code': 264,
            'flag_index': 2672
        },
        {
            'country_code': 'ZA',
            'country_name': 'South Africa',
            'phone_code': 27,
            'flag_index': 3856
        },
        {
            'country_code': 'SS',
            'country_name': 'South Sudan',
            'phone_code': 211,
            'flag_index': 4096
        },
        {
            'country_code': 'NR',
            'country_name': 'Nauru',
            'phone_code': 674,
            'flag_index': 2800
        },
        {
            'country_code': 'NI',
            'country_name': 'Nicaragua',
            'phone_code': 505,
            'flag_index': 2736
        },
        {
            'country_code': 'NP',
            'country_name': 'Nepal',
            'phone_code': 977,
            'flag_index': 2784
        },
        {
            'country_code': 'NE',
            'country_name': 'Niger',
            'phone_code': 227,
            'flag_index': 2704
        },
        {
            'country_code': 'NG',
            'country_name': 'Nigeria',
            'phone_code': 234,
            'flag_index': 2720
        },
        {
            'country_code': 'NU',
            'country_name': 'Niue',
            'phone_code': 683,
            'flag_index': 4048
        },
        {
            'country_code': 'NO',
            'country_name': 'Norway',
            'phone_code': 47,
            'flag_index': 2768
        },
        {
            'country_code': 'NF',
            'country_name': 'Norfolk Island',
            'phone_code': 672,
            'flag_index': 4032
        },
        {
            'country_code': 'PT',
            'country_name': 'Portugal',
            'phone_code': 351,
            'flag_index': 2992
        },
        {
            'country_code': 'JP',
            'country_name': 'Japan',
            'phone_code': 81,
            'flag_index': 1984
        },
        {
            'country_code': 'SE',
            'country_name': 'Sweden',
            'phone_code': 46,
            'flag_index': 3200
        },
        {
            'country_code': 'CH',
            'country_name': 'Switzerland',
            'phone_code': 41,
            'flag_index': 960
        },
        {
            'country_code': 'SV',
            'country_name': 'El Salvador',
            'phone_code': 503,
            'flag_index': 3360
        },
        {
            'country_code': 'WS',
            'country_name': 'Samoa',
            'phone_code': 685,
            'flag_index': 3824
        },
        {
            'country_code': 'RS',
            'country_name': 'Serbia',
            'phone_code': 381,
            'flag_index': 3088
        },
        {
            'country_code': 'SL',
            'country_name': 'Sierra Leone',
            'phone_code': 232,
            'flag_index': 3264
        },
        {
            'country_code': 'SN',
            'country_name': 'Senegal',
            'phone_code': 221,
            'flag_index': 3296
        },
        {
            'country_code': 'CY',
            'country_name': 'Cyprus',
            'phone_code': 357,
            'flag_index': 1120
        },
        {
            'country_code': 'SC',
            'country_name': 'Seychelles',
            'phone_code': 248,
            'flag_index': 3168
        },
        {
            'country_code': 'SA',
            'country_name': 'Saudi Arabia',
            'phone_code': 966,
            'flag_index': 3136
        },
        {
            'country_code': 'BL',
            'country_name': 'Saint Barthélemy',
            'phone_code': 590,
            'flag_index': 3904
        },
        {
            'country_code': 'ST',
            'country_name': 'Sao Tome and Principe',
            'phone_code': 239,
            'flag_index': 3344
        },
        {
            'country_code': 'SH',
            'country_name': 'Saint Helena',
            'phone_code': 290,
            'flag_index': 4080
        },
        {
            'country_code': 'KN',
            'country_name': 'Saint Kitts and Nevis',
            'phone_code': 1869,
            'flag_index': 2080
        },
        {
            'country_code': 'LC',
            'country_name': 'Saint Lucia',
            'phone_code': 1758,
            'flag_index': 2208
        },
        {
            'country_code': 'MF',
            'country_name': 'Saint Martin',
            'phone_code': 590,
            'flag_index': 4000
        },
        {
            'country_code': 'SX',
            'country_name': 'Sint Maarten',
            'phone_code': 599,
            'flag_index': 4112
        },
        {
            'country_code': 'SM',
            'country_name': 'San Marino',
            'phone_code': 378,
            'flag_index': 3280
        },
        {
            'country_code': 'VC',
            'country_name': 'Saint Vincent and the Grenadines',
            'phone_code': 1784,
            'flag_index': 3728
        },
        {
            'country_code': 'LK',
            'country_name': 'Sri Lanka',
            'phone_code': 94,
            'flag_index': 2240
        },
        {
            'country_code': 'SK',
            'country_name': 'Slovakia',
            'phone_code': 421,
            'flag_index': 3248
        },
        {
            'country_code': 'SI',
            'country_name': 'Slovenia',
            'phone_code': 386,
            'flag_index': 3232
        },
        {
            'country_code': 'SZ',
            'country_name': 'Swaziland',
            'phone_code': 268,
            'flag_index': 3392
        },
        {
            'country_code': 'SD',
            'country_name': 'Sudan',
            'phone_code': 249,
            'flag_index': 3184
        },
        {
            'country_code': 'SR',
            'country_name': 'Suriname',
            'phone_code': 597,
            'flag_index': 3328
        },
        {
            'country_code': 'SB',
            'country_name': 'Solomon Islands',
            'phone_code': 677,
            'flag_index': 3152
        },
        {
            'country_code': 'SO',
            'country_name': 'Somalia',
            'phone_code': 252,
            'flag_index': 3312
        },
        {
            'country_code': 'TJ',
            'country_name': 'Tajikistan',
            'phone_code': 992,
            'flag_index': 3472
        },
        {
            'country_code': 'TW',
            'country_name': 'Taiwan',
            'phone_code': 886,
            'flag_index': 3600
        },
        {
            'country_code': 'TH',
            'country_name': 'Thailand',
            'phone_code': 66,
            'flag_index': 3456
        },
        {
            'country_code': 'TZ',
            'country_name': 'Tanzania',
            'phone_code': 255,
            'flag_index': 3616
        },
        {
            'country_code': 'TO',
            'country_name': 'Tonga',
            'phone_code': 676,
            'flag_index': 3536
        },
        {
            'country_code': 'TC',
            'country_name': 'Turks and Caicos Islands',
            'phone_code': 1649,
            'flag_index': 3408
        },
        {
            'country_code': 'TT',
            'country_name': 'Trinidad and Tobago',
            'phone_code': 1868,
            'flag_index': 3568
        },
        {
            'country_code': 'TN',
            'country_name': 'Tunisia',
            'phone_code': 216,
            'flag_index': 3520
        },
        {
            'country_code': 'TV',
            'country_name': 'Tuvalu',
            'phone_code': 688,
            'flag_index': 3584
        },
        {
            'country_code': 'TR',
            'country_name': 'Turkey',
            'phone_code': 90,
            'flag_index': 3552
        },
        {
            'country_code': 'TM',
            'country_name': 'Turkmenistan',
            'phone_code': 993,
            'flag_index': 3504
        },
        {
            'country_code': 'TK',
            'country_name': 'Tokelau',
            'phone_code': 690,
            'flag_index': 4128
        },
        {
            'country_code': 'WF',
            'country_name': 'Wallis and Futuna',
            'phone_code': 681,
            'flag_index': 4144
        },
        {
            'country_code': 'VU',
            'country_name': 'Vanuatu',
            'phone_code': 678,
            'flag_index': 3808
        },
        {
            'country_code': 'GT',
            'country_name': 'Guatemala',
            'phone_code': 502,
            'flag_index': 1648
        },
        {
            'country_code': 'VE',
            'country_name': 'Venezuela',
            'phone_code': 58,
            'flag_index': 3744
        },
        {
            'country_code': 'BN',
            'country_name': 'Brunei',
            'phone_code': 673,
            'flag_index': 768
        },
        {
            'country_code': 'UG',
            'country_name': 'Uganda',
            'phone_code': 256,
            'flag_index': 3648
        },
        {
            'country_code': 'UA',
            'country_name': 'Ukraine',
            'phone_code': 380,
            'flag_index': 3632
        },
        {
            'country_code': 'UY',
            'country_name': 'Uruguay',
            'phone_code': 598,
            'flag_index': 3680
        },
        {
            'country_code': 'UZ',
            'country_name': 'Uzbekistan',
            'phone_code': 998,
            'flag_index': 3696
        },
        {
            'country_code': 'GR',
            'country_name': 'Greece',
            'phone_code': 30,
            'flag_index': 1632
        },
        {
            'country_code': 'ES',
            'country_name': 'Spain',
            'phone_code': 34,
            'flag_index': 1328
        },
        {
            'country_code': 'EH',
            'country_name': 'Western Sahara',
            'phone_code': 212,
            'flag_index': 1296
        },
        {
            'country_code': 'HK',
            'country_name': 'Hong Kong',
            'phone_code': 852,
            'flag_index': 1712
        },
        {
            'country_code': 'SG',
            'country_name': 'Singapore',
            'phone_code': 65,
            'flag_index': 3216
        },
        {
            'country_code': 'NC',
            'country_name': 'New Caledonia',
            'phone_code': 687,
            'flag_index': 2688
        },
        {
            'country_code': 'NZ',
            'country_name': 'New Zealand',
            'phone_code': 64,
            'flag_index': 2816
        },
        {
            'country_code': 'HU',
            'country_name': 'Hungary',
            'phone_code': 36,
            'flag_index': 1776
        },
        {
            'country_code': 'SY',
            'country_name': 'Syria',
            'phone_code': 963,
            'flag_index': 3376
        },
        {
            'country_code': 'JM',
            'country_name': 'Jamaica',
            'phone_code': 1876,
            'flag_index': 1952
        },
        {
            'country_code': 'AM',
            'country_name': 'Armenia',
            'phone_code': 374,
            'flag_index': 448
        },
        {
            'country_code': 'YE',
            'country_name': 'Yemen',
            'phone_code': 967,
            'flag_index': 3840
        },
        {
            'country_code': 'IQ',
            'country_name': 'Iraq',
            'phone_code': 964,
            'flag_index': 1872
        },
        {
            'country_code': 'IR',
            'country_name': 'Iran',
            'phone_code': 98,
            'flag_index': 1888
        },
        {
            'country_code': 'IL',
            'country_name': 'Israel',
            'phone_code': 972,
            'flag_index': 1824
        },
        {
            'country_code': 'IT',
            'country_name': 'Italy',
            'phone_code': 39,
            'flag_index': 1920
        },
        {
            'country_code': 'IN',
            'country_name': 'India',
            'phone_code': 91,
            'flag_index': 1856
        },
        {
            'country_code': 'ID',
            'country_name': 'Indonesia',
            'phone_code': 62,
            'flag_index': 1792
        },
        {
            'country_code': 'GB',
            'country_name': 'United Kingdom',
            'phone_code': 44,
            'flag_index': 1456
        },
        {
            'country_code': 'VG',
            'country_name': 'British Virgin Islands',
            'phone_code': 1284,
            'flag_index': 3760
        },
        {
            'country_code': 'IO',
            'country_name': 'British Indian Ocean Territory',
            'phone_code': 246,
            'flag_index': 3984
        },
        {
            'country_code': 'JO',
            'country_name': 'Jordan',
            'phone_code': 962,
            'flag_index': 1968
        },
        {
            'country_code': 'VN',
            'country_name': 'Vietnam',
            'phone_code': 84,
            'flag_index': 3792
        },
        {
            'country_code': 'ZM',
            'country_name': 'Zambia',
            'phone_code': 260,
            'flag_index': 3872
        },
        {
            'country_code': 'JE',
            'country_name': 'Jersey',
            'phone_code': 44,
            'flag_index': 1936
        },
        {
            'country_code': 'TD',
            'country_name': 'Chad',
            'phone_code': 235,
            'flag_index': 3424
        },
        {
            'country_code': 'GI',
            'country_name': 'Gibraltar',
            'phone_code': 350,
            'flag_index': 1536
        },
        {
            'country_code': 'CL',
            'country_name': 'Chile',
            'phone_code': 56,
            'flag_index': 1008
        },
        {
            'country_code': 'CF',
            'country_name': 'Central African Republic',
            'phone_code': 236,
            'flag_index': 928
        },
        {
            'country_code': 'CN',
            'country_name': 'China',
            'phone_code': 86,
            'flag_index': 1040
        }
    ];
}
