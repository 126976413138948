import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApidocsService } from 'src/app/services/apidocs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  manual = {};
  constructor(private router: Router, private apidocsService: ApidocsService,  private spinner: NgxSpinnerService) {

  }
  apidocs: any;
  logindoc: any;
  ngOnInit() {
    this.apidocsService.getApidocs().subscribe(data => {
      this.apidocsService.getHomePage().subscribe(man => {
        this.manual = man;
      });
      this.apidocs = data;
    });
  }

  navigateToContactUs(divId) {
    this.router.navigate(['help'], { fragment: divId });
  }

  getDoc(key) {
    return this.apidocs.records.filter(elem => elem.key === key)[0];
  }

  downloadManual() {
    this.spinner.show();
    this.apidocsService.downloadManual().subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = this.manual['manual_orig'];
        saveAs(file, filename);
      });
  }
}
