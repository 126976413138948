import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'
import { SharedService } from './shared.service';
import { User } from '../entities/user';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
    providedIn: 'root'
})
export class UserService {
    getCountry() {
        let country$ = this.http.get<User>(this.sharedService.apiUrl('user/country'))
        return country$;
    }
    constructor(private http: HttpClient, private sharedService: SharedService) { }
    getUser(username: string): Observable<User> {
        let user$ = this.http.get<User>(this.sharedService.apiUrl('user') + username)
        return user$;
    }

    getUserProfile(): Observable<User> {
        let user$ = this.http.get<User>(this.sharedService.apiUrl('user'))
        return user$;
    }

    addUser(user: any): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('user/add'), user, httpOptions).pipe(
            tap((user: any) => this.log(`added user w/ id=${user.id}`)),
            catchError(this.handleError<any>('add'))
        );
    }

    addUserProfile(user: any): Observable<any> {
        return this.http.post<any>(this.sharedService.apiUrl('user/addProfile'), user, httpOptions).pipe(
            tap((user: any) => this.log(`added user w/ id=${user.id}`)),
            catchError(this.handleError<any>('add'))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //this.messageService.add('TAS: ' + message);
    }
}
