import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Injectable()
export class MatPaginatorIntlTas extends MatPaginatorIntl {
  itemsPerPageLabel = 'Items per Page';
  nextPageLabel = 'Next Page';
  previousPageLabel = 'Previous Page';
  firstPageLabel = 'First Page';
  lastPageLabel = 'Last Page';
  of = 'of';
  subscriptionLanguage: any;
  constructor(translationService: TranslateService, sharedService: SharedService) {
    super();
    this.subscriptionLanguage = sharedService.getLan$().subscribe(lan => {
      if (lan) {
        if (lan === 'fr') {
          this.of = 'sur';
          this.itemsPerPageLabel = 'Objets par Page';
          this.nextPageLabel = 'Page Suivante';
          this.previousPageLabel = 'Page Précédente';
          this.firstPageLabel = 'Première Page';
          this.lastPageLabel = 'Dernière Page';
        }
        if (lan === 'en') {
          this.itemsPerPageLabel = 'Items per Page';
          this.nextPageLabel = 'Next Page';
          this.previousPageLabel = 'Previous Page';
          this.firstPageLabel = 'First Page';
          this.lastPageLabel = 'Last Page';
          this.of = 'of';
        }
        this.changes.next();
      }
    });
  }


  getRangeLabel = function (page, pageSize, length) {

    if (length === 0 || pageSize === 0) {
      return '0 ' + this.of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + this.of + ' ' + length;
  };

}
