import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ProcessDataSource } from '../../services/process.datasource';
import { Observable } from 'rxjs';
import { debounceTime, filter, switchMap, finalize, catchError, windowWhen } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CompanyRepresentativeService } from 'src/app/services/companyRepresentative.service';
import { CompanyRepresentativesStatusName } from 'src/app/entities/util';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/entities/company';
import { JwtHelperService } from '@auth0/angular-jwt';
import { YearService } from 'src/app/services/year.service';
import { FormControl } from '@angular/forms';
import { FileUploader } from "ng2-file-upload";
import { SharedService } from 'src/app/services/shared.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import { CategoryService } from 'src/app/services/category.service';
import { SectorService } from 'src/app/services/sector.service';
import { CityService } from 'src/app/services/city.service';
import { PostalCodeService } from 'src/app/services/postalCode.service';
import { Router } from '@angular/router';
import { RegionService } from 'src/app/services/region.service';
import { DepartmentService } from 'src/app/services/departmentService';
import { MunicipalityService } from 'src/app/services/municipality.service';
import { BranchService } from 'src/app/services/branch.service';
import { ActivityService } from 'src/app/services/activity.service';
import { saveAs } from 'file-saver';
import { ActivityDetailService } from 'src/app/services/activityDetail.service';
import { DistrictService } from 'src/app/services/district.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
export interface DialogData {
  name: string;
}
@Component({
  selector: 'tas-tin-request',
  templateUrl: './tin-request.component.html',
  styleUrls: ['./tin-request.component.css']
})
export class TinRequestComponent implements OnInit {
  public uploader: FileUploader;
  company: any;
  public searchCompanyCont = new FormControl();
  dataSource: ProcessDataSource;
  allowedMimeType = ['.xls', '.xlsx', '.pdf', '.doc', '.docx'];
  fileName: string;
  name: string;
  companies: Company[];
  statusNames = CompanyRepresentativesStatusName
  companyRepresentative: any
  ownerCreation = false;
  requestChange = false;
  filteredOptions: Observable<Company[]>;
  @ViewChild('fileUpload', { static: false }) selectedFile: any;
  individualTin: any
  individualCompanyName: any
  individualFirstName: any
  individualLastName: any
  individualIncorporationDate: any
  individualBirthDate: any
  individual: any
  foundCompanies: Company[]
  searchIndividual = false;
  sendingRequest = false;
  user: any;
  country: any;
  requestMessages = null;
  addableDropdowns = {}
  regions = []
  departments = []
  cities = []
  municipalities = []
  districts = []
  postalCodes = []
  sectors = []
  branches = []
  activities = []
  activityDetails = []
  valTin: string
  rejectReason: string
  currentWorkspace: any
  reasonType: any
  taxPayerTypeCategory: any
  applicationDocument: any;
  companyTypes = []
  defaultWorkspace: any
  constructor(
    public dialog: MatDialog, private companyRepresentativeService: CompanyRepresentativeService,
    public yearService: YearService, private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private categoryService: CategoryService,
    private postalCodeService: PostalCodeService,
    private regionService: RegionService,
    private departmentService: DepartmentService,
    private cityService: CityService,
    private toastr: ToastrService,
    private municipalityService: MunicipalityService,
    private districtService: DistrictService,
    private router: Router,
    public branchService: BranchService,
    public activityService: ActivityService,
    public activityDetailsService: ActivityDetailService,
    private sectorService: SectorService,
    private translateService: TranslateService,
    private userService: UserService,
    private companyService: CompanyService, public jwtHelper: JwtHelperService) {
    this.resetCompanyRepresentative()
  }

  resetCompanyRepresentative() {
    this.companyRepresentative = { additionalFields: {} }
    this.companyRepresentative.username = this.getUserFromToken()
    this.companyRepresentative.email = this.getUserFromToken()
  }

  ngOnInit() {
    this.sharedService.workspace$.subscribe(wrk => {
      this.defaultWorkspace = wrk;
      if (this.defaultWorkspace) {
        this.companyRepresentativeService.getCurrentCompanyRequestApplication(this.defaultWorkspace).subscribe(app => {
          this.applicationDocument = app
        })
      }
    })

    this.defaultWorkspace = localStorage.getItem("default_workspace")
    if (this.defaultWorkspace) {
      this.companyRepresentativeService.getCurrentCompanyRequestApplication(this.defaultWorkspace).subscribe(app => {
        this.applicationDocument = app
      })
    }

    this.userService.getCountry().subscribe(country => {
      this.country = country
    })
    this.categoryService.getList('category').subscribe(result => {
      this.companyTypes = result

    })
    this.sharedService.getWorkspaceObject$().subscribe(ww => {
      this.currentWorkspace = ww
      this.taxPayerTypeCategory = this.companyTypes.find(elem => elem.name === ww.type.name && elem.taxPayerType === ww.type.taxPayerType)
    })

    this.regionService.getList('region').subscribe(regions => {
      this.regions = regions
      this.departmentService.getList('department').subscribe(departments => {
        this.departments = departments
        this.cityService.getList('city').subscribe(cities => {
          this.cities = cities
          this.municipalityService.getList('municipality').subscribe(municipalities => {
            this.municipalities = municipalities
            this.districtService.getList('district').subscribe(districts => {
              this.districts = districts
            })
          })
        })
      })
    })
    this.postalCodeService.getList('postalcode').subscribe(result => {
      this.postalCodes = result
    })
    this.sectorService.getList('sector').subscribe(result => {
      this.sectors = result
      this.branchService.getList('branch').subscribe(branches => {
        this.branches = branches
        this.activityService.getList('activity').subscribe(activities => {
          this.activities = activities
          this.activityDetailsService.getList('activityDetail').subscribe(activityDetails => {
            this.activityDetails = activityDetails
          })
        })
      })
    })


    this.userService.getUserProfile().subscribe(user => {
      this.user = user;
    })
    this.attachmentSetup()
    this.filteredOptions = this.searchCompanyCont.valueChanges
      .pipe(
        debounceTime(1000),
        filter(val => val.length > 2),
        switchMap(value =>
          this.companyService.getCompanyListQuery(value)
            .pipe(
              finalize(() => {

              })
            ).
            pipe(catchError(() => { return [] })
            )
        ),
      )
  }

  downloadFile(name) {
    this.spinner.show();
    this.companyRepresentativeService.downloadAttestation(name).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = name;
        saveAs(file, filename);
      });
  }

  getBranches(branches){
    return this.companyRepresentative.type.tinCode==='IN'?branches.filter(elem=>elem.group):branches
  }

  getActivities(activities){
    return this.companyRepresentative.type.tinCode==='IN'?activities.filter(elem=>elem.subGroup):activities
  }

  isDropdownType(type) {
    return ['custom dropdown', 'sector dropdown', 'postal codes dropdown',
      'region dropdown', 'department dropdown', 'city dropdown', 'municipality dropdown',
      'district dropdown', 'branch dropdown', 'activity dropdown', 'activity detail dropdown'].includes(type)
  }

  updateAddableSelections(event, key, index, type) {

    switch (type) {
      case 'region dropdown': {
        if (!this.addableDropdowns[key]) {
          this.addableDropdowns[key] = []
        }
        if (this.addableDropdowns[key].length > 0)
          this.addableDropdowns[key][index]['department dropdown'] = []
        else
          this.addableDropdowns[key][index] = { 'department dropdown': [] }
        this.regionService.getLocations('dictionary/region/' + event.value.id + '/department').subscribe(departments => {
          this.addableDropdowns[key][index]['department dropdown'] = departments
          this.addableDropdowns[key][index]['city dropdown'] = []
          this.addableDropdowns[key][index]['municipality dropdown'] = []
          this.addableDropdowns[key][index]['district dropdown'] = []
        })
      }
        break;

      case 'department dropdown':
        this.departmentService.getLocations('dictionary/department/' + event.value.id + '/city').subscribe(cities => {
          this.addableDropdowns[key][index]['city dropdown'] = cities
          this.addableDropdowns[key][index]['municipality dropdown'] = []
          this.addableDropdowns[key][index]['district dropdown'] = []
        })
        break;
      case 'city dropdown':
        if (event.value.municipality)
          this.cityService.getLocations('dictionary/city/' + event.value.id + '/municipality').subscribe(municipalities => {
            this.addableDropdowns[key][index]['municipality dropdown'] = municipalities
            this.addableDropdowns[key][index]['district dropdown'] = []
          })
        else if (event.value.district)
          this.cityService.getLocations('dictionary/city/' + event.value.id + '/district').subscribe(districts => {
            this.addableDropdowns[key][index]['district dropdown'] = districts
            this.addableDropdowns[key][index]['municipality dropdown'] = []
          })
        break;
      case 'sector dropdown': {
        if (!this.addableDropdowns[key]) {
          this.addableDropdowns[key] = []
        }
        if (this.addableDropdowns[key].length > 0)
          this.addableDropdowns[key][index]['branch dropdown'] = []
        else
          this.addableDropdowns[key][index] = { 'branch dropdown': [] }
        this.sectorService.getLocations('dictionary/sector/' + event.value.id + '/branch').subscribe(branches => {
          this.addableDropdowns[key][index]['branch dropdown'] = branches
          this.addableDropdowns[key][index]['activity dropdown'] = []
          this.addableDropdowns[key][index]['activity detail dropdown'] = []
        })
      }
        break;

      case 'branch dropdown':
        this.branchService.getLocations('dictionary/branch/' + event.value.id + '/activity').subscribe(activities => {
          this.addableDropdowns[key][index]['activity dropdown'] = activities
          this.addableDropdowns[key][index]['activity detail dropdown'] = []
        })
        break;
      case 'activity dropdown':
        this.activityService.getLocations('dictionary/activity/' + event.value.id + '/activityDetail').subscribe(activityDetails => {
          this.addableDropdowns[key][index]['activity detail dropdown'] = activityDetails
        })

        break;
      default:
        return;
    }
  }

  getAddableDropdown(key, index, type) {
    if (this.addableDropdowns[key] && this.addableDropdowns[key][index]) {
      return this.addableDropdowns[key][index][type]
    } else {
      return []
    }

  }

  showDropdown(key, index, type) {
    if (['municipality dropdown', 'district dropdown'].includes(type)) {
      if (!this.addableDropdowns[key] || !this.addableDropdowns[key][index] || !this.addableDropdowns[key][index][type] || this.addableDropdowns[key][index][type].length === 0) {
        return false;
      }
    }
    return true
  }
  getList(key, index, type) {
    switch (type) {
      case 'custom dropdown':
        return []
      case 'region dropdown': return this.regions
      case 'sector dropdown': return this.sectors
      case 'postal codes dropdown': return this.postalCodes
      case 'department dropdown':
      case 'city dropdown':
      case 'district dropdown':
      case 'municipality dropdown':
      case 'branch dropdown':
      case 'activity dropdown':
      case 'activity detail dropdown':
        return this.getAddableDropdown(key, index, type)
      default:
        return []
    }
  }

  updateBranchesSelect(sector, key) {
    if (key === 'sector')
      this.sectorService.getLocations('dictionary/sector/' + sector.value.id + '/branch').subscribe(branches => {
        this.branches = branches
        this.activities = []
        this.activityDetails = []
        this.companyRepresentative.additionalFields['branch'] = null
        this.companyRepresentative.additionalFields['activity'] = null
        this.companyRepresentative.additionalFields['activityDetail'] = null
      })
  }
  updateActivitiesSelect(branch, key) {
    if (key === 'branch')
      this.branchService.getLocations('dictionary/branch/' + branch.value.id + '/activity').subscribe(activities => {
        this.activities = activities
        this.activityDetails = []
        this.companyRepresentative.additionalFields['activity'] = null
        this.companyRepresentative.additionalFields['activityDetail'] = null
      })
  }
  updateActivityDetailsSelect(activity, key) {
    if (key === 'activity')
      this.activityService.getLocations('dictionary/activity/' + activity.value.id + '/activityDetail').subscribe(activityDetails => {
        this.activityDetails = activityDetails
        this.companyRepresentative.additionalFields['activityDetail'] = null
      })
  }

  isChangable(key) {
    if (!this.taxPayerTypeCategory && this.currentWorkspace) {
      this.taxPayerTypeCategory = this.companyTypes.find(elem => elem.name === this.currentWorkspace.type.name && elem.taxPayerType === this.currentWorkspace.type.taxPayerType)
    }
    if (this.taxPayerTypeCategory) {
      let elem = this.taxPayerTypeCategory.additionalFields.find(elem => elem.key === key)
      if (elem && elem.changeable) {
        return true
      }
    }

    return false
  }


  updateDepartmentsSelect(region, key) {
    if (key === 'region')
      this.regionService.getLocations('dictionary/region/' + region.value.id + '/department').subscribe(departments => {
        this.departments = departments
        this.cities = []
        this.municipalities = []
        this.companyRepresentative.additionalFields['department'] = null
        this.companyRepresentative.additionalFields['city'] = null
        this.companyRepresentative.additionalFields['municipality'] = null
        this.companyRepresentative.additionalFields['district'] = null
      })

  }
  updateCitiesSelect(department, key) {
    if (key === 'department')
      this.departmentService.getLocations('dictionary/department/' + department.value.id + '/city').subscribe(cities => {
        this.cities = cities
        this.municipalities = []
        this.companyRepresentative.additionalFields['city'] = null
        this.companyRepresentative.additionalFields['municipality'] = null
        this.companyRepresentative.additionalFields['district'] = null
      })
  }
  cityHasMunicipality = true
  cityHasDistrict = false
  updateMunicipalitiesSelect(city, key) {
    if (key === 'city') {
      if (city.value.municipality)
        this.cityService.getLocations('dictionary/city/' + city.value.id + '/municipality').subscribe(municipalities => {
          this.municipalities = municipalities
          this.districts = []
          this.cityHasMunicipality = true
          this.cityHasDistrict = false
          if (!this.mandatoryFields.includes('municipality')) {
            this.mandatoryFields.push('municipality')
          }
          this.companyRepresentative.additionalFields['municipality'] = null
          this.companyRepresentative.additionalFields['district'] = null
          this.mandatoryFields = this.mandatoryFields.filter(elem => elem !== 'district')
        })
      else if (city.value.district)
        this.cityService.getLocations('dictionary/city/' + city.value.id + '/district').subscribe(districts => {
          this.districts = districts
          this.municipalities = []
          this.cityHasMunicipality = false
          this.cityHasDistrict = true
          this.companyRepresentative.additionalFields['municipality'] = null
          this.companyRepresentative.additionalFields['district'] = null
          if (!this.mandatoryFields.includes('district')) {
            this.mandatoryFields.push('district')
          }
          this.mandatoryFields = this.mandatoryFields.filter(elem => elem !== 'municipality')
        })
    }
  }


  mandatoryFields = []
  resetFields() {
    this.searchIndividual = false;
    this.individualTin = null
    this.foundCompanies = null;
    this.individual = null
    this.companyRepresentative.company = null
    this.companyRepresentative.additionalFields = {}
    this.companyRepresentative.type.additionalFields.forEach(elem => {
      if (elem.type === 'multiple wrapper') {
        this.companyRepresentative.additionalFields[elem.key] = []
        this.companyRepresentative.additionalFields[elem.key][0] = {}
      }
    })
    this.mandatoryFields = this.companyRepresentative.type.additionalFields.filter(elem => elem.required && !['header', 'sub-header'].includes(elem.type)).map(elem => elem.key)
    var addables = this.companyRepresentative.type.additionalFields.filter(elem => elem.type === 'addable')
    addables.forEach(addable => {
      this.addAddable(addable)
    })
  }
  missingMandatories() {
    var x = 10
    if (x==10){
      return false
    }
    if (this.individual){
      return false
    }
    if (!this.companyRepresentative || !this.companyRepresentative.type) {
      return true
    }
    else {
      if (this.mandatoryFields.length === 0) {
        return false;
      }
      else {
        let missingMandatory = false
        for (let i = 0; i < this.mandatoryFields.length; i++) {
          const mandKey = this.mandatoryFields[i]

          if (!this.companyRepresentative.additionalFields[mandKey] ||
            (this.companyRepresentative.additionalFields[mandKey] &&
              (null || this.companyRepresentative.additionalFields[mandKey].toString().match(/^ *$/) !== null)
            )) {
            missingMandatory = true;
          }
        }

        return missingMandatory
      }

    }
  }
  addUpload(file) {
    if (!this.companyRepresentative.files)
      this.companyRepresentative.files = []
    this.companyRepresentative.files.push(file)
  }
  removeUpload(fileKey) {
    this.companyRepresentative.files = this.companyRepresentative.files.filter(elem => elem.key !== fileKey)
  }


  addWrapper(key) {
    var wrapperElem = this.companyRepresentative.type.additionalFields.find(elem => elem.key === key)
    var elem = wrapperElem.elements[wrapperElem.elements.length - 1]
    var lastObj = this.companyRepresentative.additionalFields[key][this.companyRepresentative.additionalFields[key].length - 1]
    if (this.isEmpty(lastObj)) {
      elem.forEach(kk => {
        lastObj[kk.key] = null;
      })
    }
    this.companyRepresentative.additionalFields[key].push({})
    if (wrapperElem.elements[wrapperElem.elements.length - 1].length > 0) {
      wrapperElem.elements.push([])
      elem.forEach(kk => {
        wrapperElem.elements[wrapperElem.elements.length - 1].push(kk)
      })
    }
  }
  addAddable(addable) {
    if(!this.companyRepresentative.additionalFields[addable.key])
      this.companyRepresentative.additionalFields[addable.key] = { type: 'addable', fields: [] }
    addable.fields.forEach(f => {
      let fArr = []
      f.forEach(fl => {
        fArr.push({ key: fl.key, type: fl.type, name: fl.name })
      })
      this.companyRepresentative.additionalFields[addable.key].fields.push(fArr)
    })
  }

  removeAddable(index, key) {
    this.companyRepresentative.additionalFields[key].fields.splice(index, 1)
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  removeWrapper(key, index) {
    var wrapperElem = this.companyRepresentative.type.additionalFields.find(elem => elem.key === key)
    wrapperElem.elements.splice(index, 1)
    this.companyRepresentative.additionalFields[key].splice(index, 1)
  }

  getNextWrapperElementKey(elem) {
    var merged = [].concat.apply([], elem.elements)
    var maxKey = Math.max(...merged.map(o => o.key.split('key_')[1]))
    return maxKey + 1;
  }

  searchIndividualTin() {
    this.searchIndividual = true;
    this.individual = null
    var queryObject ={
      type:this.companyRepresentative.type.taxPayerType
    }
    if (this.individualTin){
      queryObject['tin'] = this.individualTin
    }
    if (this.companyRepresentative.type.taxPayerType==='COMPANY'){
      if (this.individualCompanyName)
      queryObject['companyName'] = this.individualCompanyName
      if (this.individualIncorporationDate)
      queryObject['incorporationDate'] = this.individualIncorporationDate
    }
    else{
      if (this.individualFirstName)
      queryObject['firstName'] = this.individualFirstName
      if (this.individualLastName)
      queryObject['lastName'] = this.individualLastName
      if (this.individualBirthDate)
      queryObject['birthDate'] = this.individualBirthDate
    }

    this.companyService.findTin(queryObject).subscribe(res => {
      if (res) {
        this.foundCompanies = res
      }
    })
  }
  individualChange(){
        this.companyRepresentative.company = new Company()
        this.companyRepresentative.company.name = this.individual['firstname'] + ' ' + this.individual['lastname'];
        this.companyRepresentative.company.tin = this.individual['tin'];
        this.companyRepresentative.company.id = this.individual['id'];
  }
  clearUpload() {
    this.companyRepresentative.files = undefined
    this.fileName = undefined
  }

  clearPayload() {

    if (this.companyRepresentative.additionalFields.region) {
      delete this.companyRepresentative.additionalFields.region.department
    }
    if (this.companyRepresentative.additionalFields.department) {
      delete this.companyRepresentative.additionalFields.department.city
    }
    if (this.companyRepresentative.additionalFields.city) {
      delete this.companyRepresentative.additionalFields.city.district
      delete this.companyRepresentative.additionalFields.city.municipality
    }

    if (this.companyRepresentative.additionalFields.sector) {
      delete this.companyRepresentative.additionalFields.sector.branch
    }
    if (this.companyRepresentative.additionalFields.branch) {
      delete this.companyRepresentative.additionalFields.branch.activity
    }
    if (this.companyRepresentative.additionalFields.activity) {
      delete this.companyRepresentative.additionalFields.activity.activityDetail
    }

    this.companyRepresentative.type.additionalFields.forEach(elem => {
      if (elem.type === 'addable') {
        if (this.companyRepresentative.additionalFields[elem.key]) {
          delete this.companyRepresentative.additionalFields[elem.key].changeable
          delete this.companyRepresentative.additionalFields[elem.key].columnStyle
          delete this.companyRepresentative.additionalFields[elem.key].duplicate
          delete this.companyRepresentative.additionalFields[elem.key].nonFinalized
          delete this.companyRepresentative.additionalFields[elem.key].required
          this.companyRepresentative.additionalFields[elem.key].fields.forEach(elem => {
            elem.forEach(f => {
              if (f.value)
                this.deleteDropdownsInData(f, f.value)
            })
          })
        }
      }
      this.deleteDropdownsInData(elem, this.companyRepresentative.additionalFields[elem.key])
    })

    delete this.companyRepresentative.type.additionalFields
  }

  deleteDropdownsInData(elem, additionalField) {
    if (elem.key !== 'region' && elem.type === 'region dropdown') {
      delete additionalField.department
    }
    if (elem.key !== 'department' && elem.type === 'department dropdown') {
      delete additionalField.city
    }
    if (elem.key !== 'city' && elem.type === 'city dropdown') {
      delete additionalField.municipality
      delete additionalField.district
    }
    if (elem.key !== 'sector' && elem.type === 'sector dropdown') {
      delete additionalField.branch
    }
    if (elem.key !== 'branch' && elem.type === 'branch dropdown') {
      delete additionalField.activity
    }
    if (elem.key !== 'activity' && elem.type === 'activity dropdown') {
      delete additionalField.activityDetail
    }
  }

  sendRequest() {
    this.clearPayload()
    this.sendingRequest = true
    this.userService.getUserProfile().subscribe(user => {
      this.companyRepresentative.ownerCreation = this.ownerCreation
      this.companyRepresentative.firstname = user.firstname
      this.companyRepresentative.lastname = user.lastname
      if (!this.companyRepresentative.company) {
        this.companyRepresentative.company = this.searchCompanyCont.value
      }
      this.spinner.show();
      this.companyRepresentativeService.sendRequest(this.companyRepresentative, this.requestChange).subscribe(val => {
        this.sendingRequest = false
        if (val.tin) {
          this.valTin = val.tin

          this.companyRepresentativeService.getCompanyWorkspaces().subscribe(val => {
            var workspaces = val['data']
            if (workspaces && workspaces.length > 0) {
              if (localStorage.getItem('default_workspace')) {
                var locWorkspc = workspaces.find(elem => elem.tin === localStorage.getItem('default_workspace'))
                this.currentWorkspace = locWorkspc ? locWorkspc : workspaces[0]
              } else {
                this.currentWorkspace = workspaces[0]
              }
              this.sharedService.setWorkspace(this.currentWorkspace)
            }

            this.companyRepresentativeService.getCurrentCompanyRequestApplication(this.valTin).subscribe(app => {
              this.applicationDocument = app

              setTimeout(() => {
                if (!this.defaultWorkspace) {
                  this.defaultWorkspace = this.valTin
                  localStorage.setItem('default_workspace', this.defaultWorkspace)
                }
                this.resetCompanyRepresentative()
                this.spinner.hide();
                window.location.reload();
              }, 500)
            })
          })
        } else {
          this.spinner.hide()
          var text = this.translateService.instant('TIN-REQUEST-MSGS.' + val.reasonType)
          if (val.duplicateValues) {
            var elem = null
            for (var k in val.duplicateValues) {
              var elem = this.companyRepresentative.type.additionalFields.find(el => el.key === k)
            }
          }
          if (elem) {
            text = this.translateService.instant('TIN-REQUEST-MSGS.' + val.reasonType).replace('XXX', elem['name'][localStorage.getItem('default_lang')] + ' - ' + val.duplicateValues[elem.key] + ' - ').replace('YYY', val.duplicateTin)
          }


          this.reasonType = val.reasonType
          this.toastr.error(text, `ERROR!!!`, {
            toastClass: "toast border-red",
            positionClass: 'toast-center-center',
            timeOut: 10000,
          });
        }

      })
    })

  }

  displayFn(company?: Company): string | undefined {
    if (company) {
      this.company = {}
      this.company = company
    }
    return company ? (company.name + " (" + (company.tin ? company.tin : "TIN N/A") + ")") : undefined;
  }
  getName(name) {
    return name[localStorage.getItem('default_lang')]
  }
  isRepValid() {
    var individualRequest = false;
    if (this.companyRepresentative.type && this.companyRepresentative.type.taxPayerType === 'INDIVIDUAL' && this.individual) {
      individualRequest = true
    }
    var ownerFields = this.companyRepresentative.address &&
      this.companyRepresentative.registrationNumber && this.companyRepresentative.registrationNumber
    return (
      this.companyRepresentative.files && this.companyRepresentative.files.length > 0
      &&
      (
        (!this.ownerCreation && ((typeof this.searchCompanyCont.value === 'object'
          && this.searchCompanyCont.value !== null) || individualRequest)) || this.ownerCreation && ownerFields
      ))
  }

  getUserFromToken() {
    if (this.jwtHelper.decodeToken(localStorage.getItem('access_token')))
      return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
    return null;
  }

  showOwnerCreationForm(create) {
    this.resetCompanyRepresentative()
    this.ownerCreation = create;
    this.requestChange = false;
  }
  showRequestChange() {
    this.requestChange = true;
    this.resetCompanyRepresentative()
    this.companyRepresentativeService.getCompanyWorkspaces().subscribe(workspaces => {
      this.companyRepresentative = workspaces['data'].find(elem => elem.tin === this.defaultWorkspace)
    })
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }

  attachmentSetup() {
    this.uploader = new FileUploader({
      url: this.sharedService.apiUrl('upload/atom'), authToken: "Bearer " + localStorage.getItem('access_token'),
      allowedFileType: ['xls', 'xlsx', 'pdf', 'doc', 'docx'],
      maxFileSize: 50 * 1024 * 1024,
      itemAlias: 'representative'
    });
    this.uploader.onWhenAddingFileFailed = (item) => {
      this.uploader.clearQueue();
      this.fileName = undefined
    }
    this.uploader.onAfterAddingFile = (fileItem) => {
      this.fileName = (fileItem.file.name);
    }
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.uploader.clearQueue();
      var responsePath = JSON.parse(response);
      if (responsePath.status !== 'OK') {
        var errMessage = responsePath.unhandled ? responsePath.message : "DIALOG." + responsePath.message
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          width: '500px',
          data: { title: errMessage }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      } else {
        this.companyRepresentative.files = [responsePath.data]
      }
      this.spinner.hide();
    };

  }

  selectChange = (event: any, name) => {

    this.companyRepresentative[name] = event.data
  };

  openUploadDialog(): void {
    this.spinner.show();
    this.uploader.uploadAll();
    this.selectedFile.nativeElement.value = '';
  }
}

@Component({
  selector: 'upload-tin-request-file-confirmation-dialog',
  templateUrl: 'upload-tin-request-file-dialog.html',
})
export class UploadTinRequestFileDialog implements OnInit {
  uploader
  selectedFile: any;
  constructor(
    public dialogRef: MatDialogRef<UploadTinRequestFileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private spinner: NgxSpinnerService) {
    this.uploader = data['uploader'];
    this.selectedFile = data['selectedFile']
  }
  processingStages = []
  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  uploadExcel() {
    this.spinner.show();
    this.dialogRef.close();
    this.uploader.uploadAll();
    this.clearSelectedFile();
  }
  clearSelectedFile() {
    this.selectedFile.nativeElement.value = '';
  }


}

