import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MultiStepFormComponent } from '../../components/multi-step-form/multi-step-form.component';
import { HomeComponent } from '../../components/home/home.component';
import { LoginComponent } from '../../components/login/login.component';
import { AuthGuard } from '../../guards/auth.guard';
import { DevelopmentComponent } from '../../components/development/development.component';
import { ProcessViewComponent } from '../../components/process/process-view/process-view.component';
import { ProcessStepComponent } from '../../components/process/process-step/process-step.component';
import { FormCreatorComponent } from 'src/app/components/form-creation/form-creator/form-creator.component';
import { AdministrationComponent } from 'src/app/components/settings/administration/administration.component';
import { HelpComponent } from 'src/app/components/help/help.component';
import { ApidocComponent } from 'src/app/components/help/apidoc/apidoc.component';
import { ErrorpageComponent } from 'src/app/components/errorpage/errorpage.component';
import { ExternalFormsComponent } from 'src/app/components/external-forms/external-forms.component';
import { TinRequestComponent } from 'src/app/components/tin-request/tin-request.component';
import { ProfileGuard } from 'src/app/guards/profile.guard';
import { TinrequestGuard } from 'src/app/guards/tinrequest.guard';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard, ProfileGuard, TinrequestGuard], runGuardsAndResolvers: 'always' },
  { path: 'home/:year', component: HomeComponent, canActivate: [AuthGuard, ProfileGuard, TinrequestGuard],runGuardsAndResolvers: 'always' },
  { path: 'settings', component: AdministrationComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'development', component: DevelopmentComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'formcreation', component: FormCreatorComponent, canActivate: [AuthGuard] },
  // { path: 'tin-request', component: TinRequestComponent, canActivate: [AuthGuard, ProfileGuard], runGuardsAndResolvers: 'always'  },
  { path: 'formcreation/:reportId/:stageId', component: FormCreatorComponent, canActivate: [AuthGuard] },
  { path: 'process/:id/:step', component: ProcessStepComponent, canActivate: [AuthGuard, ProfileGuard, TinrequestGuard],runGuardsAndResolvers: 'always'},
  { path: 'process/:year/:reportType', component: ProcessStepComponent, canActivate: [AuthGuard, ProfileGuard, TinrequestGuard],runGuardsAndResolvers: 'always'},
  { path: 'process/:id', component: ProcessViewComponent, canActivate: [AuthGuard, ProfileGuard, TinrequestGuard],runGuardsAndResolvers: 'always' },
  { path: 'external/:mcasreportId/:reportId', component: ExternalFormsComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'apidoc/_ops_/:api', component: ApidocComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'apidoc/:declaration_type/:api_single', component: ApidocComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'error/:error', component: ErrorpageComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  // { path: 'process/:processKey', component: MultiStepFormComponent, canActivate: [AuthGuard] },
  { path: 'auth', component: LoginComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
