import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http: HttpClient) {

  }

  getJSONFromFile(path): Observable<any> {
    return this.http.get(path);
  }


  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
      };
      fileReader.readAsText(file);
    }
  }
}
