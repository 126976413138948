import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './shared.service';


@Injectable({
  providedIn: 'root'
})

export class DictionaryService extends BaseService {
   httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(public http: HttpClient, public sharedService: SharedService) {
    super(http, sharedService);
   }
  public fetchStreets(value: string, layerId: string): Observable<string[]> {

    return layerId && value ?
    this.http.get<string[]>(this.sharedService.apiUrl('dictionary/streets/' + layerId + '/' + value)).pipe(
      map((res) => {
        return res;
      })
    ) : of([]);
  }

  public fetchMunicipality(param1: string, param2: string): Observable<string[]> {
    return param1 ?
    this.http.get<string[]>(this.sharedService.apiUrl('dictionary/address/municipality/' + param1 + ( param2? '/' +param2 : ''))).pipe(
      map((res) => {
        return res;
      })
    ) : of([]);
  }
  
  

  public addStreet(layer: string, street: string): Observable<string[]> {
    return this.http.post<any>(this.sharedService.apiUrl('dictionary/streets/' + layer), {name: street}, this.httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }
  public updateBuilding(buildingId: string, streetId: number, houseNumber: string): Observable<string[]> {
    return this.http.put<any>(this.sharedService.apiUrl('dictionary/building/' + buildingId), {streetId, houseNumber}, this.httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public addBuilding(streetId: number, houseNumber: string, geoJson: any): Observable<string[]> {
    return this.http.post<any>(this.sharedService.apiUrl('dictionary/building'), {streetId, houseNumber, geoJson}, this.httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public addBuildingData(buildingId: string, street: string, stringNumber: string): Observable<string[]> {
    return this.http.post<any>(this.sharedService.apiUrl('dictionary/streets'), {street, stringNumber}, this.httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // addCompany(company: any): Observable<any> {
  //   return this.http.post<any>(this.sharedService.apiUrl('company/add'), company, httpOptions).pipe(
  //     tap((company: any) => this.log(`added company w/ id=${company.id}`)),
  //     catchError(this.handleError<any>('add'))
  //   );
  // }

  // return this.http.post<any>(this.sharedService.apiUrl('company/add'), company, httpOptions).pipe(
  //   tap((company: any) => this.log(`added company w/ id=${company.id}`)),
  //   catchError(this.handleError<any>('add'))
  // );



}
