import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FileUploader } from "ng2-file-upload";
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {
  public uploader: FileUploader;
  @ViewChild('fileUpload', { static: false }) selectedFile: any;
  allowedMimeType = ['.xls', '.xlsx', '.pdf', '.doc', '.docx'];
  @Input() title
  fileName: string;
  fileKey: string;
  fileUploaded = false;
  @Output() emitUpload: EventEmitter<any> = new EventEmitter();
  @Output() emitRemove: EventEmitter<any> = new EventEmitter();
  constructor(private sharedService: SharedService, private spinner: NgxSpinnerService, public dialog: MatDialog,) { }

  ngOnInit() {
    this.uploader = new FileUploader({
      url: this.sharedService.apiUrl('upload/atom'), authToken: "Bearer " + localStorage.getItem('access_token'),
      allowedFileType: ['xls', 'xlsx', 'pdf', 'doc', 'docx'],
      maxFileSize: 50 * 1024 * 1024,
      itemAlias: 'representative'
    });
    this.uploader.onWhenAddingFileFailed = (item) => {
      this.uploader.clearQueue();
      this.fileName = undefined
    }
    this.uploader.onAfterAddingFile = (fileItem) => {
      this.fileName = (fileItem.file.name);
    }
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.uploader.clearQueue();
      var responsePath = JSON.parse(response);
      if (responsePath.status !== 'OK') {
        var errMessage = responsePath.unhandled ? responsePath.message : "DIALOG." + responsePath.message
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          width: '500px',
          data: { title: errMessage }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      } else {
        this.emitUpload.emit(responsePath.data)
        this.fileKey=responsePath.data.key
        this.fileUploaded = true
      }
      this.spinner.hide();
    };

  }

  clearUpload() {
    this.fileUploaded = false
    this.fileName = undefined
    this.emitRemove.emit(this.fileKey)
  }

  removeFile() {
    this.fileUploaded = false
    this.fileName = undefined
  }

  openUploadDialog(): void {
    this.spinner.show();
    this.uploader.uploadAll();
    this.selectedFile.nativeElement.value = '';
  }
}
