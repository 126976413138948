export class Process {
    public id: number;
    public key: string;
    public name: string;
    public status: string;
    public progress: number;
    public due_date: Date;
    public started_on: Date;
    public year: string;
  }
