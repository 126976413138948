// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apihost: 'https://sl-atom-stg-tas-server.revenuedev.org/web',
  apihostDomain : 'https://sl-atom-stg-tas-server.revenuedev.org',
  authServerhost: 'https://sso.revenuedev.org/?returnUrl=https://sl-atom-stg-govin.revenuedev.org',
  authServerhostLogout: 'https://sso.revenuedev.org/logout?returnUrl=https://sl-atom-stg-govin.revenuedev.org',
  whitelistDomain: "sl-atom-stg-tas-server.revenuedev.org",
  blacklistDomain: "sl-atom-stg-tas-server.revenuedev.org/web/auth",
  immatriculationWeb: "https://sl-atom-stg-immatriculation.revenuedev.org",
  boundary: [ -13.5,6.8,-10.5,9.9 ],
  default_lang: 'en'
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
