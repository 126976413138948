import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { first, switchMap, filter, distinctUntilChanged } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedService } from 'src/app/services/shared.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  //templateUrl: './login.component.html',
  template: '<html><body></body></html>',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;
  public error: string;
  public loading: false;
  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, public jwtHelper: JwtHelperService,
    private sharedService: SharedService, private toastr: ToastrService) { }
  ngOnInit() {
    this.route.queryParams
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(params => {
        var token = params['nonce']
        var urlParam = params['url']
        if (!token) {
          this.auth.getRedirectLink()
            .pipe(first())
            .subscribe(
              result => {
                window.location.href = result['redirectLink']+(urlParam ? "/" + encodeURIComponent(urlParam.substring(1,urlParam.length)) : "" )
              },
              err => {
                this.handleError(err)
              }
            );
        }
        else {                   
          this.auth.getTokenFromNonce(token)
            .pipe(first())
            .subscribe(
              result => {
                
                if (token === undefined) {
                  window.location.href = result['redirectLink']
                } else {
                  var tokenLocal = result['usrToken']
                  localStorage.setItem('access_token', tokenLocal);
                  this.sharedService.updateAccessToken()
                  window.location.href = urlParam
                }
              },
              err => {
                this.handleError(err)
              }
            );
        }

      });
  }

  login() {
    this.auth.login(this.username, this.password)
      .pipe(first())
      .subscribe(
        result => { this.router.navigate(['home']); },
        err => {
          if (err.status !== undefined) {
            if (err.status === 0) {
              this.error = 'ERRORS.CONNECTION_FAILED'
            }
            else if (err.status === 401) {
              this.error = 'ERRORS.' + err.error.message;
            }
          }
        }
      );
  }

  handleError(err) {
    if (err.status !== undefined) {
      if (err.status === 0) {
        this.toastr.error('Unable to connect to server', `ERROR!!!`, {
          toastClass: "toast border-red",
          positionClass: 'toast-center-center',
          timeOut: 10000,
        });
      }
      else if (err.status === 401) {
        // this.error = 'ERRORS.' + err.error.message;
      }
    }
  }


}
