import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CompanyRepresentativeService } from '../services/companyRepresentative.service';
import { UserService } from '../services/user.service';
@Injectable({
    providedIn: 'root'
})
export class ProfileGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService, private companyRepresentativeService: CompanyRepresentativeService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // this.userService.getUserProfile().subscribe(userProfile => {
        //     this.companyRepresentativeService.getCompanyWorkspaces().subscribe(workspaces => {
        //         if (!userProfile || !this.fieldSet(userProfile.firstname) || !this.fieldSet(userProfile.firstname) || !this.fieldSet(userProfile.idnumber)) {
        //             this.router.navigate(['/settings'])
        //         }
        //         return true;
        //     })
        // })
        return true;
    }
    fieldSet(field){
        return field!==undefined && field.length>0
    }
}
