import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProcessService } from '../../../services/process.service';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Process } from '../../../entities/process';
import { FileService } from '../../../services/file.service';
import { switchMap } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-process-step',
  templateUrl: './process-step.component.html',
  styleUrls: ['./process-step.component.css']
})
export class ProcessStepComponent implements OnInit {
  currentProcessId: number;
  //stage structure
  stageStructure: any;
  stageGlobals: any;
  stageKey: String;
  process: Process = new Process();
  //stage data
  stageData: any;
  reportType: string;

  constructor(public processService: ProcessService, private reportService: ReportService, private route: ActivatedRoute, private sharedService: SharedService) { }

  ngOnInit() {

      this.initialize()
  }
  initialize(){
    this.process = new Process();
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      this.stageKey = params.get('step')
      this.reportType = params.get('reportType')
      if (this.reportType !== undefined && this.reportType !== null) {
        this.stageKey = 'page-1'
      }
      return this.processService.getProcess(params.get('id'))
    }))
      .subscribe(process => {
        this.reportService.getReportPage(process['key'], this.stageKey).subscribe(
          data => {
            if (process !== null) {
              this.process = process;
              this.currentProcessId = this.process.id;
              this.stageData = process['stages'].find(stage => stage !== null && stage.key === this.stageKey);
              this.stageGlobals = process['globals']?process['globals']:{}
            } else {
              this.process['year'] = (new Date()).getFullYear() + ''
              this.process['key'] = this.reportType
            }
            this.stageStructure = data
          },
          err => {
            console.log(err);
          }
        );
      });
  }

}