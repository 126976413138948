import { Component, OnInit } from '@angular/core';
import { Company } from '../../../entities/company';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CompanyService } from 'src/app/services/company.service';
import { SharedService } from 'src/app/services/shared.service';
import { User } from 'src/app/entities/user';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {
  color = 'accent';
  checked = false;
  disabled = false;
  company: Company;
  user: User;
  noPass = false;
  changePass = false;
  actualPassword = '';
  newPassword = '';
  repeatPassword = '';
  apiEnabled = false;
  wrongCurrentPassword = false;

  public myreg = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$'/gi;

  pass1Wrong = false;
  pass2Wrong = false;

  constructor(public jwtHelper: JwtHelperService,
    public router: Router, public companyService: CompanyService,
    public userService: UserService, public sharedService: SharedService,
    private toastr: ToastrService, private spinner: NgxSpinnerService) {

  }
  ngOnInit() {
    this.company = new Company();
    this.user = new User();
    if (!this.isTokenExpired()) {
      this.company.username = this.getUserFromToken();
      this.companyService.getCompany(this.company.username).subscribe(
        data => {
          if (data !== null) {
            this.company = data;
          }
        },
        err => {
          console.log(err);
        }
      );

      this.userService.getUser(this.company.username).subscribe(userData => {
        if (userData) {
          this.user = userData;
          this.apiEnabled = true;
          if (this.user.password !== undefined) {
            this.noPass = false;
          }
        }

      }, err => {
        console.log(err);
      });

    }
  }

  saveApiUser() {
    if (!this.isTokenExpired()) {
      this.spinner.show();
      this.user.password = this.newPassword;
      this.user.username = this.getUserFromToken();
      if (this.changePass) {
        this.user['currentPassword'] = this.actualPassword;
      }
      this.userService.addUser(this.user).subscribe(
        data => {

          this.spinner.hide();
          if (data['error'] && data['error'] === 'WRONG_PASSWORD') {
            this.wrongCurrentPassword = true;
          } else {

            this.wrongCurrentPassword = false;
            this.actualPassword = '';
            this.newPassword = '';
            this.repeatPassword = '';
            this.changePass = false;
            this.noPass = false;
            this.sharedService.updateCompany(this.company);

            this.toastr.success('Success!', `API password successfully saved`, {
              toastClass: 'toast border-red',
              timeOut: 3000
            });
          }

        },
        err => {

          console.log(err);
        }
      );
    }
  }

  saveCompany() {

    if (!this.isTokenExpired()) {

      this.companyService.addCompany(this.company).subscribe(
        data => {
          // this.actualPassword = "";
          // this.newPassword = "";
          // this.repeatPassword = "";
          // this.changePass = false;
          // this.noPass = false;
          this.sharedService.updateCompany(this.company);
          this.toastr.success('Success!', `Company ${this.company.companyName} successfully saved`, {
            toastClass: 'toast border-red',
            timeOut: 3000
          });
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  isTokenExpired() {
    return this.jwtHelper.isTokenExpired();
  }
  getUserFromToken() {
    return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
  }
  acitvateClicked(ev) {
    if (ev.checked) {
      this.noPass = false;
    } else {
      this.newPassword = '';
      this.repeatPassword = '';
      this.actualPassword = '';
      this.changePass = false;
      this.noPass = false;
    }
  }

  setPassword(firstTime) {
    if (firstTime) {
      this.noPass = true;
    }
    if (!firstTime) {
      this.changePass = true;
    }
  }

}
