import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(public http: HttpClient, public sharedService: SharedService) {

  }

  getLocations(resourceUrl: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
      map(res => {
        return res;
      })
    );
  }

  getList(resourceUrl: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
      map(res => {
        return res;
      })
    );
  }

}



