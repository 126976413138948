import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DictionaryService } from 'src/app/services/dictionary.service';

@Component({
  selector: 'app-street-adder',
  templateUrl: './street-adder.component.html',
  styleUrls: ['./street-adder.component.css']
})
export class StreetAdderComponent implements OnInit {
  @Input() street;
  @Input() layerID;
  @Output() streetIdEmitter = new EventEmitter<any>();
  constructor(public dictionaryService: DictionaryService) { }

  ngOnInit() {
  }
  addStreet() {
    this.dictionaryService.addStreet(this.layerID, this.street).subscribe((res) => {
      this.street = '';
      this.streetIdEmitter.emit(res['id']);
    });
  }
}
