import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../../process/process-view/process-view.component';
import { EventEmitter } from 'events';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  text: string;
  emitter: EventEmitter;
  obj: any;
  processes = [];
  proceed = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService) {
    this.text = data['text'];
    this.obj = data['obj'];
    this.proceed = this.obj['FORM_SAVE'] ? this.obj : null;
  }

  ngOnInit() {

  }

  onCancelClick(): void {
    this.dialogRef.close(true);
  }
  onConfirmClick(): void {
    this.sharedService.confirmDialogAction(this.obj);
    this.dialogRef.close(false);
  }

  onConfirmClickNoAction() {
    this.sharedService.confirmDialogActionProceed(this.proceed);
    this.dialogRef.close(false);
  }

}
