import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

  getRefreshTime(expTime) {
    var t1 = new Date().getTime() / 1000
    var dif = t1 - expTime;
    return 1000 * (dif * (-1) - 5);
  }
}