import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/entities/user';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { Countries } from 'src/app/util/countries';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userProfile: User;
  countries = new Countries()
  countryCodes = new FormControl('');
  options = this.countries.countries;
  filteredOptions: Observable<any[]>;
  constructor(private userService: UserService, private toastr: ToastrService,
    private sharedService: SharedService, ) { }

  ngOnInit() {
    this.countries = new Countries();
    this.filteredOptions = this.countryCodes.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.userService.getUserProfile().subscribe(val => 
      {
        if (!val){
          this.userProfile = new User()
        }else{
          this.userProfile = val
        }
        if (this.userProfile.countrycode)
        this.countryCodes.setValue(this.options.find(elem => elem.phone_code === this.userProfile.countrycode))
      }
      )
      this.userService.getCountry().subscribe(country => {
        if (!this.userProfile.phonenumber){
          this.userProfile.phonenumber = country['phone_code']
        }
        
      })

  }
  saveUserProfile(){
    if (this.countryCodes.value){
      this.userProfile.countrycode = this.countryCodes.value.phone_code
    }
    this.userService.addUserProfile(this.userProfile).subscribe(result => {
      this.toastr.success('Success!', this.userProfile['id']? `User profile successfully updated`:`User profile successfully added. You can navigate to tin requests now`, {
        toastClass: "toast border-red",
        timeOut: this.userProfile['id']?3000:5000
      });
      if (!this.userProfile['id']){
        this.sharedService.createdUserProfile()
      }
      this.userProfile['id'] = result.id
     
    })
  }

  displayFn(country?: any): string | undefined {
    if (country)
    return country.country_code + " +"+country.phone_code
  }
  private _filter(value: string): any[] {
    if (typeof value === 'string'){
      const filterValue = value.toLowerCase();

      return this.options.filter(option => option.country_name.toLowerCase().includes(filterValue));
    }
    
  }

  showInfo(){
    return (
      this.userProfile 
      && this.userProfile['id']!==null 
      && 
      (!this.userProfile.firstname 
        || !this.userProfile.lastname 
        || !this.userProfile.idnumber))
  }

}
