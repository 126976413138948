import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../services/auth.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CompanyService } from "src/app/services/company.service";
import { SharedService } from "src/app/services/shared.service";
import { CompanyRepresentativeService } from "src/app/services/companyRepresentative.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "tas-top-menu",
  templateUrl: "./tas-top-menu.component.html",
  styleUrls: ["./tas-top-menu.component.css"],
})
export class TasTopMenuComponent implements OnInit {
  @ViewChild("tinrequests", { static: false }) tinrequests: ElementRef;
  title: string = "GOVIN";
  user: string = "";
  userProfileExists = false;
  username: string;
  tin: string;
  companyName: string;
  userProfileAdded: any;
  lang: string;
  workspace: any;
  subscriptionCompany: any;
  subscriptionUserProfile: any;
  subscriptionToken: any;
  workspaces: any[];
  constructor(public translate: TranslateService, public auth: AuthService, public companyRepresentativeService: CompanyRepresentativeService,
    public userService: UserService, public jwtHelper: JwtHelperService, public sharedService: SharedService, public companyService: CompanyService) {
    translate.setDefaultLang(environment.default_lang || 'fr');
    this.setLang(localStorage.getItem("default_lang") || environment.default_lang || 'fr');
  }

  ngOnInit() {
    this.initializeTopMenu()
  }

  initializeTopMenu() {
    this.subscriptionUserProfile = this.sharedService.userProfileEmitter.subscribe((val) => {
      this.userProfileExists = val ? true : false;
      this.subscriptionCompany = this.sharedService.change.subscribe(
        (company) => {
          this.tin = company.tin;
          this.companyName = company.companyName;
        }
      );
      this.subscriptionToken = this.sharedService.updateToken.subscribe(
        (val) => {
          if (val) {
            this.username = this.getUserFromToken();
            this.companyService.getCompany(this.username).subscribe((data) => {
              if (data) {
                this.tin = data.tin;
                this.companyName = data.companyName;
              }
            });
          }
        }
      );
      if ((this.username = this.getUserFromToken())) {
        this.companyService.getCompany(this.username).subscribe((data) => {
          if (data) {
            this.tin = data.tin;
            this.companyName = data.companyName;
          }
        });
      }

      this.companyRepresentativeService
        .getCompanyWorkspaces()
        .subscribe((val) => {
          this.workspaces = val["data"];
          if (this.workspaces && this.workspaces.length > 0) {
            if (localStorage.getItem("default_workspace")) {
              var locWorkspc = this.workspaces.find(
                (elem) => elem.tin === localStorage.getItem("default_workspace")
              );
              this.workspace = locWorkspc || this.workspaces[0];
            } else {
              this.workspace = this.workspaces[0];
            }
            this.sharedService.setWorkspace(this.workspace);
          } else {
            localStorage.removeItem("default_workspace");
          }
        });

      this.userProfileAdded = this.sharedService.userProfileCreated.subscribe(
        (result) => {
          this.tinrequests.nativeElement.classList.add("tin-glow");
        }
      );
    });
  }

  changeWorkspace(event) {
    this.workspace = event.value;
    this.sharedService.setWorkspace(this.workspace);
  }


  logout() {
    localStorage.removeItem("access_token");
    window.location.href = this.sharedService.authServerUrlLogout();
  }

  isTokenExpired() {
    return this.jwtHelper.isTokenExpired();
  }

  getUserFromToken() {
    if (this.jwtHelper.decodeToken(localStorage.getItem("access_token")))
      return this.jwtHelper.decodeToken(localStorage.getItem("access_token"))
        .username;
    return null;
  }

  setLang(lang) {
    this.sharedService.setLan(lang);
    this.lang = lang;
    this.translate.use(lang);
  }
}
