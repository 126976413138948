import {Component, Input} from "@angular/core";

@Component({
  selector: "map-preview",
  template: `
    <div style="margin: 15px 0px;">
        <div>
          Current Location LatLon: {{locationLatLng}}
        </div>
        <div>
        Selected Building Id: {{selectedBuildingDetails | json}}
        </div>
        <label>BBOX</label>
        <input [(ngModel)]="bbox" style="width: 550px">
        <br/>
        search by ID
        <input [(ngModel)]="buildingId_" style="width: 550px">
        <button (click)="searchById()">find</button>
        <br/>
        <app-map *ngIf="!buildingId" [findMyLocation]="true" [bbox]="bbox" (selectedBuildingDetails)="selectedBuildingDetails = $event" (myLocationLatLng)="locationLatLng =$event"></app-map>
        <app-map *ngIf="buildingId" [findMyLocation]="true" [bbox]="bbox" (selectedBuildingDetails)="selectedBuildingDetails = $event"  (myLocationLatLng)="locationLatLng =$event" [buildingId]="buildingId"></app-map>
    </div>
  `
})
export class MapPreviewComponent {
  buildingId_
  buildingId
  selectedBuildingDetails
  bbox
  locationLatLng

  searchById() {
    this.buildingId = null
    setTimeout(()=>{
      this.buildingId = this.buildingId_
    }, 100)

  }
}
