import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CompanyRepresentativeService } from '../services/companyRepresentative.service';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class TinrequestGuard implements CanActivate {
    constructor(private router: Router, private companyRepresentativeService: CompanyRepresentativeService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.companyRepresentativeService.getCompanyWorkspaces().subscribe(workspaces => {
            if (workspaces['total'] === 0) {
                window.location.href = environment.immatriculationWeb + '/tin-request';
                return false;
            }
        });
        return true;
    }
}
