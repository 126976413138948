import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SharedService} from "../../services/shared.service";
import {Observable, throwError} from "rxjs";
import {catchError} from 'rxjs/operators';
import {BaseService} from '../../services/base.service';

@Injectable()
export class MapService extends BaseService{

  constructor(public http: HttpClient, public sharedService: SharedService){
    super(http, sharedService);
  }

  // getFeatureInfo$(bbox, width, height, x, y): Observable<any>{
  //   return this.http.get(
  //     this.sharedService.apiUrl('map/feature-info', '?bbox='+bbox+
  //       '&height='+height+'&width='+width+'&x='+x+'&y='+y))
  //     .pipe(
  //       catchError(error => this.sharedService.handleError(error))
  //     );
  // }

  getRegistered$(bbox){
    return this.http.get(
      this.sharedService.apiUrl('map/feature/registered', '?bbox='+ bbox))
      .pipe(
        catchError((error, caught) => throwError(error))
      );
  }

  getNearestBuildings$(latlng){
    return this.http.get(
      this.sharedService.apiUrl('map/feature/nearest', '?latlng='+ latlng))
      .pipe(
        catchError((error, caught) => throwError(error))
      );
  }

  getFeatureList$(bbox){
    return this.http.get(
      this.sharedService.apiUrl('map/feature', '?bbox='+ bbox))
      .pipe(
        catchError((error, caught) => throwError(error))
      );
  }

  getFeatureById$(id){
    return this.http.get(
      this.sharedService.apiUrl('map/feature/' + id, ''))
      .pipe(
        catchError((error, caught) => throwError(error))
      );
  }

  getTinsByBuildingId$(id):Observable<any>{
    return this.http.get(
      this.sharedService.apiUrl('map/feature/building-tin-balance/' + id, ''))
      .pipe(
        catchError((error, caught) => throwError(error))
      );
  }

  addBuilding$(body): Observable<any> {
    return this.http.post<any>(this.sharedService.apiUrl('map/building'), body)
      .pipe(
        catchError((error, caught) => throwError(error))
      );
  }

  updateBuilding$(id, body): Observable<any> {
    return this.http.put<any>(this.sharedService.apiUrl(`map/building/${id}`), body)
      .pipe(
        catchError((error, caught) => throwError(error))
      );
  }

}
